import ContactFormErrors from "../../interfaces/formErrors/ContactFormErrors";
import isEmail from "validator/lib/isEmail";

class QuickContactFormValidator {
  static validate({
    email,
    phoneNumber,
  }: ContactFormValues): ContactFormErrors | null {
    let formErrors: ContactFormErrors | null = null;
    if (email && !isEmail(email)) {
      formErrors = {
        email: `L'email est invalide`,
      };
    }
    if (!phoneNumber || phoneNumber.length < 6) {
      formErrors = {
        ...(formErrors ?? {}),
        phoneNumber: "Numéro invalide",
      };
    }
    return formErrors;
  }
}

export default QuickContactFormValidator;
