import React from "react";
import RecaptchaModalProps from "../../../../interfaces/props/RecaptchaModalProps";
import { Modal } from "@mui/material";
import { Root } from "./styledComponents";
import ReCAPTCHA from "react-google-recaptcha";

const CaptchaModal = ({
  onChangeCaptchaValue,
  open,
  onClose,
}: RecaptchaModalProps) => (
  <Modal open={open} onBackdropClick={onClose}>
    <Root>
      <ReCAPTCHA
        sitekey={"6LceCVgfAAAAAO68VzPgExbvBIWmnfNg1JWFcN2e"}
        onChange={(value) => onChangeCaptchaValue(value)}
      />
    </Root>
  </Modal>
);

export default CaptchaModal;
