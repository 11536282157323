import React from "react";
import { Alert, Snackbar, Slide } from "@mui/material";
import { TransitionLeft } from "./Transitions";
import SnackbarProps from "../../../../interfaces/props/SnackbarProps";

const ConfirmationBar = ({ open, onClose, message }: SnackbarProps) => (
  <Snackbar
    open={open}
    anchorOrigin={{
      horizontal: "right",
      vertical: "top",
    }}
    autoHideDuration={5000}
    onClose={onClose}
    TransitionComponent={TransitionLeft}
  >
    <Alert onClose={onClose} severity="success" sx={{ width: "100%" }}>
      {message}
    </Alert>
  </Snackbar>
);

export default ConfirmationBar;
