import React from "react";
import { Alert, Snackbar } from "@mui/material";
import SnackbarProps from "../../../../interfaces/props/SnackbarProps";
import { TransitionLeft } from "./Transitions";

const ErrorBar = ({ message, open, onClose }: SnackbarProps) => (
  <Snackbar
    open={open}
    anchorOrigin={{
      horizontal: "right",
      vertical: "top",
    }}
    autoHideDuration={6000}
    onClose={onClose}
    TransitionComponent={TransitionLeft}
  >
    <Alert onClose={onClose} severity="error" sx={{ width: "100%" }}>
      {message}
    </Alert>
  </Snackbar>
);

export default ErrorBar;
