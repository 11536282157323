import React from "react";
import "react-multi-carousel/lib/styles.css";
import { CustomPageContent, Root, Title } from "./styledComponents";
import { uniformedPlatform } from "../data";
import CarouselElement from "./components";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const UniformedPlatform = ({ onOpenModal }: { onOpenModal: () => void }) => (
  <Root id={"unifiedPlatform"}>
    <Title>Plateforme intégrée</Title>
    <CustomPageContent>
      <Carousel
        responsive={responsive}
        autoPlay={false}
        swipeable={false}
        draggable={false}
        shouldResetAutoplay={false}
      >
        {uniformedPlatform.map((element, index) => (
          <CarouselElement key={index} {...element} onContactUs={onOpenModal} />
        ))}
      </Carousel>
    </CustomPageContent>
  </Root>
);

export default UniformedPlatform;
