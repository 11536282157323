import ContactFormErrors from "../../interfaces/formErrors/ContactFormErrors";
import isEmail from "validator/lib/isEmail";

class ContactFormValidator {
  static validate({
    email,
    phoneNumber,
    name,
    companyName,
  }: ContactFormValues): ContactFormErrors | null {
    let formErrors: ContactFormErrors | null = null;
    if (!email || !isEmail(email)) {
      formErrors = {
        email: `L'email est invalide`,
      };
    }
    if (!phoneNumber || phoneNumber.length < 6) {
      formErrors = {
        ...(formErrors ?? {}),
        phoneNumber: "Numéro invalide",
      };
    }
    if (!companyName || companyName.length < 3) {
      formErrors = {
        ...(formErrors ?? {}),
        companyName: "Entrez un nom valide",
      };
    }
    if (!name || name.length < 3) {
      formErrors = {
        ...(formErrors ?? {}),
        name: "Entrez un nom valide",
      };
    }
    return formErrors;
  }
}

export default ContactFormValidator;
