import React from "react";
import { LinkProps } from "../../interfaces/props/LinkProps";
import styled from "styled-components";
import Text from "../atoms/Text";

export const Container = styled.a`
  text-decoration: none;
  color: white;
`;

const Link = ({ className, to, title }: LinkProps) => (
  <Container className={className} href={to}>
    <Text>{title}</Text>
  </Container>
);

export default Link;
