import React from "react";
import { GridRoot, Root } from "./styledComponents";
import SectionTitle from "../../../atoms/SectionTitle";
import { Grid } from "@mui/material";
import { offers } from "../data";
import OfferItem from "./components";
import AskPricesButton from "../../../molecules/AskPricesButton";

const OfferBlock = ({ onOpenModal }: { onOpenModal: () => void }) => (
  <Root id={"offer"}>
    <SectionTitle>Votre logistique de A à Z</SectionTitle>
    <GridRoot container display={"flex"} columnSpacing={3} rowSpacing={2}>
      {offers.map((offer, index) => (
        <Grid item xs={6} sm={4} lg={2} key={index} flex={1} display={"flex"}>
          <OfferItem {...offer} />
        </Grid>
      ))}
    </GridRoot>
    <AskPricesButton onClick={onOpenModal} />
  </Root>
);

export default OfferBlock;
