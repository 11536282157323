import React from "react";
import styled from "styled-components";
import Text from "../atoms/Text";
import ButtonProps from "../../interfaces/props/ButtonProps";

const Button = styled.div`
  display: compact;
  padding: 15px 60px 15px 60px;
  background-color: #32d74b;
  border-radius: 10px;
  min-width: inherit;
  margin-top: 45px;
  cursor: pointer;
  @media (max-width: 900px) {
    padding: 15px 30px 15px 30px;
  }
`;

const Title = styled(Text)`
  color: white;
  font-size: 18px;
  font-weight: bold;
  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const AskPricesButton = ({ onClick }: ButtonProps) => (
  <Button onClick={onClick}>
    <Title>Demander un tarif</Title>
  </Button>
);

export default AskPricesButton;
