import React, { useState } from "react";
import {
  Field,
  FormContainer,
  Root,
  SubmitButton,
  Subtitle,
  Title,
} from "./styledComponents";
import isEmail from "validator/lib/isEmail";
import ContactFormErrors from "../../../../interfaces/formErrors/ContactFormErrors";
import QuickContactFormValidator from "../../../../helpers/formValidators/QuickContactFormValidator";

const WeCallYou = ({
  onSubmit,
}: {
  onSubmit: (values: ContactFormValues) => void;
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [formErrors, setFormError] = useState<ContactFormErrors | null>();

  function onSubmitForm(e: any) {
    let newFormErrors = QuickContactFormValidator.validate({
      email,
      phoneNumber,
    });
    e.preventDefault();
    setFormError(newFormErrors);
    if (newFormErrors) {
      setFormError(newFormErrors);
      return;
    }
    onSubmit({ email, phoneNumber });
    setEmail("");
    setPhoneNumber("");
  }

  return (
    <Root id={"weCallYou"}>
      <Title>On vous rappelle</Title>
      <Subtitle>
        Vous êtes à deux clics d’une logistique exemplaire, faites-vous appeler
        :
      </Subtitle>
      <FormContainer onSubmit={onSubmitForm}>
        <Field
          required
          helperText={
            formErrors && formErrors.phoneNumber ? formErrors.phoneNumber : ""
          }
          error={!!formErrors && !!formErrors.phoneNumber}
          value={phoneNumber}
          variant={"standard"}
          label={"Téléphone"}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
        <Field
          error={!!formErrors && !!formErrors.email}
          helperText={formErrors && formErrors.email ? formErrors.email : ""}
          value={email}
          variant={"standard"}
          label={"Email (facultatif)"}
          onChange={(event) => setEmail(event.target.value)}
        />
        <SubmitButton type={"submit"} title={"Envoyer"} />
      </FormContainer>
    </Root>
  );
};

export default WeCallYou;
