import styled from "styled-components";
import Link from "../../../molecules/Link";

export const Root = styled.div`
  background: black;
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const Container = styled.div`
  background: black;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
`;

export const CloseContainer = styled.div`
  margin-bottom: 15px;
`;

export const CustomLink = styled(Link)`
  max-height: 20px;
  margin-bottom: 10px;
  flex: 1;
  padding-left: 15px;
`;
