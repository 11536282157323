import styled from "styled-components";
import { PageContent } from "../../../layout/PageContent";
import Text from "../../../atoms/Text";
import SectionTitle from "../../../atoms/SectionTitle";
import { Grid } from "@mui/material";

export const GridRoot = styled(Grid)`
  justify-content: space-between;
  @media (max-width: 1230px) {
    justify-content: center;
  }
`;

export const Root = styled(PageContent)`
  margin-top: 30px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-bottom: 100px;
`;

export const OfferItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  padding: 45px 20px 30px 20px;
  background-color: #faf9f9;
`;

export const OfferItemImgSubcontainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  background-color: transparent;
  border-color: white;
  border: solid;
  border-color: white;
  border-width: 0.5px;
  padding: 25px;
  border-radius: 10px;
`;

export const OfferItemImgContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  margin-bottom: 10px;
  padding: 3px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

export const OfferItemImg = styled.img`
  height: 60px;
  width: 60px;
  aspect-ratio: 1/1;
  object-fit: contain;
`;

export const OfferItemTitle = styled(Text)`
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

export const OfferItemSubtitle = styled(Text)`
  text-align: center;
  font-weight: 100;
`;
