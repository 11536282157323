import React from "react";
import {
  OfferItemContainer,
  OfferItemImg,
  OfferItemImgContainer,
  OfferItemImgSubcontainer,
  OfferItemSubtitle,
  OfferItemTitle,
} from "./styledComponents";

const OfferItem = ({ imgSrc, title, body, color }: OfferItemProps) => (
  <OfferItemContainer>
    <OfferItemImgContainer backgroundColor={color}>
      <OfferItemImgSubcontainer>
        <OfferItemImg src={imgSrc} />
      </OfferItemImgSubcontainer>
    </OfferItemImgContainer>
    <OfferItemTitle>{title}</OfferItemTitle>
    <OfferItemSubtitle>{body}</OfferItemSubtitle>
  </OfferItemContainer>
);

export default OfferItem;
