import React from "react";
import {
  Container,
  ImageContainer,
  BackgroundImage,
  Root,
  Introduction,
  Title,
  Subtitle,
  Form,
  StyledForm,
  FormContainer,
  IntroductionContainer,
  ColoredBackground,
} from "./styledComponents";
import { Grid } from "@mui/material";
import AskPricesButton from "../../../molecules/AskPricesButton";
import HeaderImage from "/src/images/header.png";

const HeaderBlock = ({
  onOpenModal,
  onSubmitForm,
}: {
  onOpenModal: () => void;
  onSubmitForm: (values: ContactFormValues) => void;
}) => (
  <Container>
    <ColoredBackground />
    <ImageContainer>
      <BackgroundImage src={HeaderImage} />
    </ImageContainer>
    <Root>
      <Grid
        container
        flexDirection={"row-reverse"}
        justifyContent={"space-between"}
      >
        <Introduction
          item
          xs={12}
          md={7}
          flexDirection={"column"}
          alignItems={"flex-end"}
        >
          <IntroductionContainer>
            <Title>Votre logisticien e-commerce.</Title>
            <Subtitle>
              Reduisez vos délais et augmentez votre satisfaction client
            </Subtitle>
            <AskPricesButton onClick={onOpenModal} />
          </IntroductionContainer>
        </Introduction>
        <Form item xs={12} md={5} flexDirection={"column"}>
          <FormContainer>
            <StyledForm onSubmit={onSubmitForm} />
          </FormContainer>
        </Form>
      </Grid>
    </Root>
  </Container>
);

export default HeaderBlock;
