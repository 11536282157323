import React from "react";
import {
  BodyText,
  LegalLink,
  Logo,
  GridItem,
  Root,
  RowContainer,
  SocialContainer,
  SocialLogo,
  SocialLogoContainer,
  CopyRight,
} from "./styledComponents";
import SymplLogo from "/src/images/logo_blanc@2x.png";
import Facebook from "/src/images/facebook.png";
import Linkedin from "/src/images/linkedin.png";
import Insta from "/src/images/insta.png";
import { Grid } from "@mui/material";
import {
  cookiesFileURL,
  legalFileURL,
  facebookPageURL,
  instagramPageURL,
  linkedInPageURL,
} from "../../../data/constants";

const GridItemElement = ({ children }: { children: any }) => (
  <GridItem item xs={12} md={3}>
    <RowContainer>{children}</RowContainer>
  </GridItem>
);

const Footer = () => (
  <Root>
    <Grid
      container
      rowSpacing={2}
      justifyContent={"space-between"}
      display={"flex"}
      alignItems={"center"}
    >
      <GridItemElement>
        <Logo src={SymplLogo} />
        <BodyText>
          Sympl logisticien spécialisé dans tous les secteurs du e-commerce.
          Sympl gère toutes les étapes de votre logistique de la réception de la
          marchandise, en passant par le contrôle de marchandise jusqu’à
          l’expédition.
        </BodyText>
      </GridItemElement>
      <GridItemElement>
        <LegalLink to={legalFileURL}>
          <BodyText>Mentions légales</BodyText>
        </LegalLink>
        <LegalLink to={cookiesFileURL}>
          <BodyText>Politique de confidentialité</BodyText>
        </LegalLink>
      </GridItemElement>
      <GridItemElement>
        <SocialContainer>
          <SocialLogoContainer href={facebookPageURL}>
            <SocialLogo src={Facebook} />
          </SocialLogoContainer>
          <SocialLogoContainer href={instagramPageURL}>
            <SocialLogo src={Insta} />
          </SocialLogoContainer>
          <SocialLogoContainer href={linkedInPageURL}>
            <SocialLogo src={Linkedin} />
          </SocialLogoContainer>
        </SocialContainer>
        <CopyRight>Copyright © 2022 Sympl.fr</CopyRight>
      </GridItemElement>
    </Grid>
  </Root>
);

export default Footer;
