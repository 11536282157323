export default {
  translation: {
    cookiesAlert: {
      message:
        "Nous utilisons des cookies afin d'analyser les données " +
        'de nos visiteurs, améliorer notre site Web, afficher un contenu ' +
        'personnalisé et vous faire vivre une expérience inoubliable.',
      approve: 'Accepter',
      decline: 'Refuser',
    },
  }
};
