import styled from "styled-components";
import { PageContent } from "../../../layout/PageContent";
import SectionTitle from "../../../atoms/SectionTitle";
import Text from "../../../atoms/Text";
import { TextField as MUITextField } from "@mui/material";
import ContainedButton from "../../../molecules/ContainedButton";

export const Root = styled(PageContent)`
  flex: 1;
  background-color: #0fc1f7;
  flex-direction: column;
  padding-bottom: 100px;
  display: flex;
`;

export const Title = styled(SectionTitle)`
  color: white;
  text-align: center;
`;

export const Subtitle = styled(Text)`
  color: white;
  font-weight: 100;
  font-size: 20px;
  text-align: center;
  margin-bottom: 50px;
`;

export const FormContainer = styled.form`
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
  max-height: 90px;
  flex: 1;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Field = styled(MUITextField)`
  input {
    color: white;
    padding-bottom: 10px;
  }
  & .MuiInput-underline {
    :after,
    :before {
      border-bottom-color: white;
    }
  }
  & .MuiInput-underline:hover {
    border-bottom-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & label {
    color: white;
    font-weight: 100;
  }
  && {
    @media (max-width: 900px) {
      margin-top: 10px;
    }
  }
  flex: 1;
  width: 100%;
  max-width: 400px;
`;

export const SubmitButton = styled(ContainedButton)`
  background-color: white;
  & p {
    color: black;
  }
  margin-top: 0;
  @media (max-width: 900px) {
    margin-top: 15px;
  }
`;
