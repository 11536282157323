import styled from "styled-components";
import { PageContent } from "../../layout/PageContent";
import Link from "../../molecules/Link";
import Text from "../../atoms/Text";

export const Root = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  position: absolute;
  z-index: 1000;
`;

export const Container = styled(PageContent)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  height: 70%;
  max-width: 200px;
  object-fit: contain;
  @media (max-width: 900px) {
    height: 50%;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1230px) {
    display: none;
  }
`;

export const MenuIconContainer = styled.div`
  cursor: pointer;
  display: none;
  @media (max-width: 1230px) {
    display: flex;
  }
`;

export const NavBarLink = styled(Link)`
  margin-left: 30px;
  font-weight: bold;
`;

export const DemoButtonContainer = styled.a`
  cursor: pointer;
  padding: 10px 20px 10px 20px;
  background-color: #0fc1f7;
  border-radius: 10px;
  margin-left: 30px;
  text-decoration: none;
`;

export const DemoButtonTitle = styled(Text)`
  color: white;
  font-size: 14px;
  font-weight: bold;
`;
