import React from "react";
import {
  CarouselElementContainer,
  CarouselElementSecondContainer,
  ElementContainer,
  ElementLogo,
  ElementText,
  Root,
  Title,
} from "./styledComponents";
import { usersTalks } from "../data";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    spacing: 2,
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const UsersTalkBlock = () => (
  <Root>
    <Title>Nos utilisateurs témoignent</Title>
    <Carousel
      responsive={responsive}
      autoPlay={false}
      swipeable={false}
      draggable={false}
      shouldResetAutoplay={false}
    >
      {usersTalks.map((element, index) => (
        <CarouselElementContainer key={index}>
          <CarouselElementSecondContainer>
            <ElementContainer>
              <ElementText>{element.text}</ElementText>
              <ElementLogo src={element.logo} />
            </ElementContainer>
          </CarouselElementSecondContainer>
        </CarouselElementContainer>
      ))}
    </Carousel>
  </Root>
);

export default UsersTalkBlock;
