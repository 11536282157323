import React, { useState } from "react";
import {
  Container,
  Field,
  SubmitButton,
  SubmitText,
  Subtitle,
  Title,
} from "./styledComponents";
import ContactFormProps from "../../../../interfaces/props/ContactFormProps";
import ContactFormValidator from "../../../../helpers/formValidators/ContactFormValidator";
import ContactFormErrors from "../../../../interfaces/formErrors/ContactFormErrors";

const ContactForm = ({ className, onSubmit }: ContactFormProps) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [formErrors, setFormError] = useState<ContactFormErrors | null>();

  function onSubmitForm(event: any) {
    let newFormErrors = ContactFormValidator.validate({
      email,
      phoneNumber,
      companyName,
      name,
    });
    event.preventDefault();
    setFormError(newFormErrors);
    if (newFormErrors) {
      setFormError(newFormErrors);
      return;
    }
    onSubmit({
      phoneNumber,
      companyName,
      name,
      email,
    });
    setEmail("");
    setPhoneNumber("");
    setCompanyName("");
    setName("");
  }

  return (
    <Container className={className} onSubmit={onSubmitForm}>
      <Title>Nous contacter</Title>
      <Subtitle>
        Pour une expédition en 1 jour, un SAV efficace, un contrôle de votre
        marchandise
      </Subtitle>
      <Field
        required
        error={!!formErrors && !!formErrors.name}
        helperText={!!formErrors && !!formErrors.name ? formErrors.name : ""}
        variant={"standard"}
        label={"Nom"}
        value={name}
        onChange={(event) => setName(event.target.value)}
        sx={{ marginBottom: 1 }}
      />
      <Field
        required
        error={!!formErrors && !!formErrors.email}
        helperText={!!formErrors && !!formErrors.email ? formErrors.email : ""}
        variant={"standard"}
        label={"Email"}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        sx={{ marginBottom: 1 }}
      />
      <Field
        required
        error={!!formErrors && !!formErrors.phoneNumber}
        helperText={
          !!formErrors && !!formErrors.phoneNumber ? formErrors.phoneNumber : ""
        }
        variant={"standard"}
        label={"Téléphone"}
        value={phoneNumber}
        onChange={(event) => setPhoneNumber(event.target.value)}
        sx={{ marginBottom: 1 }}
      />
      <Field
        required
        error={!!formErrors && !!formErrors.companyName}
        helperText={
          !!formErrors && !!formErrors.companyName ? formErrors.companyName : ""
        }
        variant={"standard"}
        label={"Société"}
        value={companyName}
        onChange={(event) => setCompanyName(event.target.value)}
        sx={{ marginBottom: 1 }}
      />
      <SubmitButton type={"submit"}>
        <SubmitText>Envoyer</SubmitText>
      </SubmitButton>
    </Container>
  );
};

export default ContactForm;
