import React from "react";
import CarouselItemProps from "../../../../interfaces/props/CarouselItemProps";
import styled from "styled-components";
import Text from "../../../atoms/Text";
import ContainedButton from "../../../molecules/ContainedButton";

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Container = styled.div`
  max-width: 70%;
  flex: 1;
  flex-direction: row;
  display: flex;
  @media (max-width: 900px) {
    max-width: 80%;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const CarouselLeftSider = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-right: 30px;
  @media (max-width: 900px) {
    align-items: center;
    margin-right: 0;
  }
`;

const CarouselTitle = styled(Text)`
  font-size: 25px;
  font-weight: 500;
  text-align: right;
  @media (max-width: 900px) {
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
  }
`;

const CarouselImage = styled.img`
  //flex: 1;
  max-width: 55%;
  object-fit: contain;
  aspect-ratio: 1/1;
  @media (max-width: 900px) {
    max-width: 80%;
  }
`;

const Button = styled(ContainedButton)`
  margin-top: 30px;
  background-color: #32d74b;
`;

const CarouselElement = ({ image, onContactUs, title }: CarouselItemProps) => (
  <MainContainer>
    <Container>
      <CarouselLeftSider>
        <CarouselTitle>{title}</CarouselTitle>
        <Button title={"Nous contacter"} onClick={onContactUs} />
      </CarouselLeftSider>
      <CarouselImage src={image} />
    </Container>
  </MainContainer>
);

export default CarouselElement;
