import React from "react";
import styled from "styled-components";
import SectionTitle from "../../../atoms/SectionTitle";
import Text from "../../../atoms/Text";
import { PageContent } from "../../../layout/PageContent";

export const Root = styled(PageContent)`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

export const CarouselElementContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const CarouselElementSecondContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 30px;
`;

export const Title = styled(SectionTitle)`
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
`;

export const ElementContainer = styled.div`
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 0 35px #0000000d;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const ElementText = styled(Text)`
  margin-bottom: 30px;
`;
export const ElementLogo = styled.img`
  width: 60px;
  aspect-ratio: 1/1;
`;
