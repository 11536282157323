import styled from "styled-components";
import { TextField as MUITextField } from "@mui/material";
import Text from "../../../atoms/Text";

export const Title = styled(Text)`
  font-size: 30px;
  margin-bottom: 30px;
  color: white;
  font-weight: bold;
  width: 100%;
`;

export const Subtitle = styled(Text)`
  color: white;
  font-weight: 200;
  width: 100%;
  margin-bottom: 15px;
`;

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Field = styled(MUITextField)`
  input {
    color: white;
    padding-bottom: 10px;
  }
  & .MuiInput-underline {
    :after,
    :before {
      border-bottom-color: white;
    }
  }
  & .MuiInput-underline:hover {
    border-bottom-color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & label {
    color: white;
    font-weight: 100;
  }
  flex: 1;
  width: 100%;
`;

export const SubmitButton = styled.button`
  cursor: pointer;
  display: compact;
  padding: 15px 60px 15px 60px;
  background-color: white;
  border-radius: 10px;
  min-width: inherit;
  margin-top: 45px;
  border: none;
`;

export const SubmitText = styled(Text)`
  color: black;
  font-size: 18px;
  font-weight: 700;
`;
