import React from "react";
import styled from "styled-components";
import Text from "../atoms/Text";
import ButtonProps from "../../interfaces/props/ButtonProps";

export const AskPricesButton = styled.button`
  display: flex;
  padding: 15px 60px 15px 60px;
  align-items: center;
  justify-content: center;
  background-color: #0ddb38;
  border-radius: 10px;
  min-width: inherit;
  border: none;
  cursor: pointer;
`;

export const AskPricesTitle = styled(Text)`
  color: white;
  font-size: 15px;
  font-weight: bold;
`;

interface Props extends ButtonProps {
  title: string;
}

const ContainedButton = (props: Props) => (
  <AskPricesButton onClick={props.onClick} className={props.className}>
    <AskPricesTitle>{props.title}</AskPricesTitle>
  </AskPricesButton>
);

export default ContainedButton;
