class ServerResponse {
  success?: boolean;
  error?: {
    message: string;
    code: string;
  };
  public static deserialize(json: any): ServerResponse {
    let response = new ServerResponse();
    console.log(json);
    if (json.success) {
      response.success = json.success as boolean;
    }
    if (json.errorCode || json.errorMessage) {
      let code = json.errorCode ?? 0;
      let message =
        code === 400
          ? "Le captacha n'est pas valide."
          : json.errorMessage ?? "";
      response.error = {
        message,
        code,
      };
    }
    return response;
  }
}

export default ServerResponse;
