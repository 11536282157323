import styled from "styled-components";
import Text from "../../../atoms/Text";
import { PageContent } from "../../../layout/PageContent";
import SectionTitle from "../../../atoms/SectionTitle";

export const Root = styled(PageContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f3f0;
  padding-bottom: 60px;
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

export const CompanyLogo = styled.img`
  object-fit: scale-down;
  height: 60px;
  width: 120px;
  margin-left: 10px;
`;

export const CustomSectionTitle = styled(SectionTitle)`
  margin-top: 30px;
`;
