import React from "react";
import styled from "styled-components";
import { PageContent } from "../../../layout/PageContent";
import Text from "../../../atoms/Text";

export const Root = styled(PageContent)`
  margin-top: 60px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-bottom: 100px;
  @media (max-width: 1000px) {
    padding: 0 0 0 0;
  }
`;

export const TextSideContainer = styled.div<{
  side: "right" | "left";
  backgroundColor: string;
}>`
  flex: 1;
  display: flex;
  padding: 5px;
  background: ${(props) => props.backgroundColor};
  flex-direction: ${(props) => (props.side === "left" ? "row-reverse" : "row")};
`;

export const TextSideSubcontainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 15px 0 15px;
  border: solid;
  border-color: white;
  border-width: 0.5px;
  background-color: transparent;
`;

export const ImageSideContainer = styled.img`
  object-fit: cover;
  width: 100%;
  background-color: grey;
  aspect-ratio: 7/4;
`;

export const ItemTitle = styled(Text)`
  color: white;
  font-weight: bold;
  font-size: 35px;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 25px;
  }
`;

export const Filler = styled.div<{ side: "right" | "left" }>`
  background-color: ${(props) => (props.side === "right" ? "white" : "black")};
  margin-right: ${(props) => (props.side === "right" ? "5px" : 0)};
  margin-left: ${(props) => (props.side === "left" ? "5px" : 0)};
  height: 4px;
  flex: 1;
  margin-top: 15px;
`;
