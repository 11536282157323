import React from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import { Close } from "@mui/icons-material";

export const ModalContainer = styled.div`
  //align-items: center;
  //justify-content: center;
  margin: auto;
  display: flex;
  width: 100vw;
  height: 100vh;
`;

export const CloseIconContainer = styled.div``;

export const CloseIcon = styled(Close)`
  color: white;
  padding: 5px 5px 5px 5px;
  border-radius: 100px;
  :active {
    background-color: grey;
    color: black;
  }
  margin-bottom: 10px;
`;

export const RootFormContainer = styled.div`
  margin: auto;
  padding: 10px;
  background-color: #00c2ff;
  border-radius: 15px;
  border: solid;
  border-color: white;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
  @media (max-width: 900px) {
    margin: unset;
    border: none;
    box-shadow: none;
    border-radius: 0;
    flex: 1;
  }
`;

export const FormContainer = styled.div`
  padding: 0 45px 60px 45px;
`;

export const StyledForm = styled(ContactForm)`
  display: flex;
  max-width: 400px;
  flex: 1;
  @media (max-width: 900px) {
    max-width: unset;
  }
`;
