import { Drawer as MUIDrawer } from "@mui/material";
import React from "react";
import {
  CloseContainer,
  Container,
  CustomLink,
  Root,
} from "./styledComponents";
import { Close } from "@mui/icons-material";
import { LinkProps } from "../../../../interfaces/props/LinkProps";

const Drawer = ({
  open,
  onClose,
  links,
}: {
  open: boolean;
  onClose: () => void;
  links: LinkProps[];
}) => (
  <MUIDrawer open={open} anchor={"right"}>
    <Root>
      <Container>
        <CloseContainer onClick={onClose}>
          <Close style={{ color: "white" }} />
        </CloseContainer>
        {links.map((props, index) => (
          <CustomLink key={index} {...props} />
        ))}
      </Container>
    </Root>
  </MUIDrawer>
);

export default Drawer;
