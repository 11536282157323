import React from "react";
import styled from "styled-components";
import { PageContent } from "../../layout/PageContent";
import Text from "../../atoms/Text";
import { Grid } from "@mui/material";
import { Link } from "gatsby";

export const Root = styled(PageContent)`
  background-color: black;
  padding-top: 60px;
  padding-bottom: 60px;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  max-width: 200px;
  object-fit: contain;
  margin-bottom: 10px;
`;

export const RowContainer = styled.div`
  flex-direction: column;
  display: flex;
  @media (max-width: 1240px) {
    align-items: center;
  }
`;

export const BodyText = styled(Text)`
  color: white;
  font-size: 14px;
  font-weight: 100;
  display: inline-block;
  text-align: left;
  @media (max-width: 1240px) {
    text-align: center;
  }
`;

export const CopyRight = styled(BodyText)`
  text-align: center;
`;

export const SocialContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

export const SocialLogo = styled.img`
  max-width: 45px;
  object-fit: contain;
  margin: 10px;
`;

export const SocialLogoContainer = styled.a``;

export const LegalLink = styled(Link)``;

export const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
