import React from "react";
import SectionTitle from "../../../atoms/SectionTitle";
import {
  Filler,
  ImageSideContainer,
  ItemTitle,
  Root,
  TextSideContainer,
  TextSideSubcontainer,
} from "./styledComponents";
import { Grid } from "@mui/material";
import { whySympl } from "../data";

const WhySymplBlock = () => (
  <Root id={"whySympl"}>
    {whySympl.map(({ imageSrc, titleBackgroundColor, title }, index) => (
      <Grid
        key={index}
        container
        flexDirection={index % 2 === 0 ? "row" : "row-reverse"}
        display={"flex"}
        flex={1}
        rowSpacing={0}
        spacing={0}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ aspectRatio: "7/4" }}
          display={"flex"}
        >
          <TextSideContainer
            side={index % 2 === 0 ? "right" : "left"}
            backgroundColor={titleBackgroundColor}
          >
            <TextSideSubcontainer>
              <ItemTitle>{title}</ItemTitle>
            </TextSideSubcontainer>
          </TextSideContainer>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <ImageSideContainer src={imageSrc} />
        </Grid>
      </Grid>
    ))}
  </Root>
);

export default WhySymplBlock;
