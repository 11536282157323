import styled from "styled-components";

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  @media (min-width: 1230px) {
    padding: 0 8% 0 8%;
  }
  @media (max-width: 1230px) {
    padding: 0 5% 0 5%;
  }
`;
