import { LinkProps } from "../../../interfaces/props/LinkProps";

export const links: LinkProps[] = [
  {
    title: "Services",
    to: "#offer",
  },
  {
    title: "Plus de détails",
    to: "#whySympl",
  },
  {
    title: "Plateforme intégrée",
    to: "#unifiedPlatform",
  },
  {
    title: "On vous rappelle",
    to: "#weCallYou",
  },
];
