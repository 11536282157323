import styled from "styled-components";
import { Grid } from "@mui/material";
import Text from "../../../atoms/Text";
import ContactForm from "../ContactForm";
import { PageContent } from "../../../layout/PageContent";
import exp from "constants";

export const Form = styled(Grid)`
  display: flex;
  align-items: flex-start;
  @media (max-width: 900px) {
    align-items: unset;
  }
`;

export const Introduction = styled(Grid)`
  @media (max-width: 900px) {
    background: url("https://sympl-assets.s3.eu-west-1.amazonaws.com/img/showcase/home-header%402x.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
  }
  background: transparent;
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding-top: 30px;
`;

export const Root = styled(PageContent)`
  padding-top: 150px;
  @media (max-width: 900px) {
    display: flex;
    padding: 0;
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 700px;
  object-fit: cover;
  flex: 1;
`;

export const ColoredBackground = styled.div`
  background-color: #f7f3f0;
  flex: 1;
  width: 100%;
  height: 800px;
  @media (max-width: 900px) {
    height: 1200px;
  }
  position: absolute;
  z-index: -2;
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 600px;
  z-index: -1;
  display: none;
  @media (min-width: 900px) {
    display: flex;
  }
`;

export const Container = styled.div`
  min-height: 600px;
  width: 100%;
`;

export const Title = styled(Text)`
  color: white;
  font-size: 42px;
  font-weight: 800;
  margin-bottom: 10px;
  text-align: right;
  @media (max-width: 900px) {
    font-size: 35px;
  }
`;

export const Subtitle = styled(Text)`
  color: white;
  font-size: 38px;
  font-weight: 500;
  text-align: right;
  @media (max-width: 900px) {
    font-size: 25px;
  }
`;

export const FormContainer = styled(PageContent)`
  background-color: #00c2ff;
  padding: 60px 45px 60px 45px;
  border-radius: 15px;
  border: solid;
  border-color: white;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
  @media (max-width: 900px) {
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const IntroductionContainer = styled.div`
  display: flex;
  padding-top: 30px;
  align-items: flex-end;
  flex-direction: column;
  flex: 1;
  @media (max-width: 1230px) {
    padding-top: 60px;
    align-items: flex-end;
  }
`;

export const StyledForm = styled(ContactForm)`
  display: flex;
  background-color: #0fc1f7;
  margin-top: 15px;
  max-width: 400px;
  flex: 1;
  @media (max-width: 900px) {
    max-width: unset;
  }
`;
