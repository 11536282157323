import React from "react";
import { CompanyLogo, CustomSectionTitle, Root } from "./styledComponents";
import SectionTitle from "../../../atoms/SectionTitle";
import { companiesLogo } from "../data";
import { Grid } from "@mui/material";

const CompanyBlock = () => (
  <Root>
    <CustomSectionTitle>Ils nous font confiance</CustomSectionTitle>
    <Grid container>
      {companiesLogo.map((logo, index) => (
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          key={index}
        >
          <CompanyLogo src={logo} />
        </Grid>
      ))}
    </Grid>
  </Root>
);

export default CompanyBlock;
