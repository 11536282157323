import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeaderBlock from "./HeaderBlock";
import OfferBlock from "./OfferBlock";
import CompanyBlock from "./CompanyBlock";
import NavBar from "../../organisms/NavBar";
import WhySymplBlock from "./WhySymplBlock";
import UniformedPlatform from "./UniformedPlatform";
import UsersTalkBlock from "./UsersTalkBlock";
import WeCallYou from "./WeCallYou";
import Footer from "../../organisms/Footer";
import CaptchaModal from "../../organisms/modal/Captcha/CaptchaModal";
import axios from "axios";
import ServerResponse from "../../../classes/ServerResponse";
import ConfirmationBar from "./Snackbars/ConfirmationBar";
import ErrorBar from "./Snackbars/ErrorBar";
import FormModal from "./FormModal";

const RootContainer = styled.div`
  height: 100vh;
  width: 100vw;
  flex: 1;
`;

const HomePage = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [captchaModalOpen, setCaptchaModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState<ContactFormValues | null>();
  const [error, setError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoading(false), 4000);
  }, [loading]);

  function onSubmitFormSuccess() {
    setFormValues(null);
    setSubmitted(true);
    setTimeout(() => {
      setSubmitted(false);
    }, 3000);
  }

  function onChangeCaptchaValue(value: string | null) {
    if (!value || !formValues) {
      return;
    }
    const { email, phoneNumber, name, companyName } = formValues;

    setCaptchaModalOpen(false);
    axios
      .post("https://live.sympl.fr/api/contact", {
        email,
        phoneNumber,
        companyName,
        firstname: name,
        captchaValue: value,
      })
      .then((value) => {
        const response = ServerResponse.deserialize(value.data);
        setLoading(false);
        if (response.success) {
          onSubmitFormSuccess();
          return;
        }
        if (response && response.error) {
          setError(response!.error!.message);
        }
      });
  }

  function onSubmitForm(values: ContactFormValues) {
    setFormOpen(false);
    setCaptchaModalOpen(true);
    setFormValues(values);
  }

  function onOpenModal() {
    setFormOpen(true);
  }

  return (
    <RootContainer>
      <FormModal
        onSubmitForm={onSubmitForm}
        open={formOpen}
        onClose={() => setFormOpen(false)}
      />
      <ConfirmationBar
        message={"Merci ! Nous reviendrons vers vous très vite !"}
        onClose={() => setSubmitted(false)}
        open={submitted}
      />
      <ErrorBar
        message={error ?? ""}
        onClose={() => setError(null)}
        open={!!error}
      />
      <CaptchaModal
        open={captchaModalOpen}
        onClose={() => setCaptchaModalOpen(false)}
        onChangeCaptchaValue={onChangeCaptchaValue}
      />
      <>
        <NavBar />
        <HeaderBlock onSubmitForm={onSubmitForm} onOpenModal={onOpenModal} />
        <CompanyBlock />
        <OfferBlock onOpenModal={onOpenModal} />
        <WhySymplBlock />
        <UniformedPlatform onOpenModal={onOpenModal} />
        <UsersTalkBlock />
        <WeCallYou onSubmit={onSubmitForm} />
        <Footer />
      </>
    </RootContainer>
  );
};

export default HomePage;
