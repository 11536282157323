import * as React from "react";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import content from "../data/home";
import "../style/app.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "../components/pages/home";
import CookiesManager from "../components/molecules/CookiesManager";
import { Helmet } from "react-helmet";

const theme = createTheme();
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: content,
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

// markup
const IndexPage = () => (
  <div id={"wrapper"}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Sympl</title>
    </Helmet>
    <ThemeProvider theme={theme}>
      <CookiesManager />
      <Home />
    </ThemeProvider>
  </div>
);

export default IndexPage;
