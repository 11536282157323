import React from "react";
import {
  CloseIcon,
  FormContainer,
  ModalContainer,
  RootFormContainer,
  StyledForm,
} from "../styledComponents";
import { Modal } from "@mui/material";

const FormModal = ({
  onClose,
  onSubmitForm,
  open,
}: {
  onClose: () => void;
  onSubmitForm: (values: ContactFormValues) => void;
  open: boolean;
}) => (
  <Modal open={open} onBackdropClick={() => console.log("test")}>
    <ModalContainer>
      <RootFormContainer>
        <CloseIcon onClick={onClose} />
        <FormContainer>
          <StyledForm onSubmit={onSubmitForm} />
        </FormContainer>
      </RootFormContainer>
    </ModalContainer>
  </Modal>
);

export default FormModal;
