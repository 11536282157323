import React, { useState } from "react";
import {
  Container,
  DemoButtonContainer,
  DemoButtonTitle,
  LinksContainer,
  Logo,
  MenuIconContainer,
  NavBarLink,
  Root,
} from "./styledComponents";
import LogoBlanc from "../../../images/logo_blanc@2x.png";
import { links } from "./data";
import { NavBarProps } from "../../../interfaces/props/NavBarProps";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "./Drawer";

const NavBar = ({ className }: NavBarProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        links={links}
      />
      <Root className={className}>
        <Container>
          <Logo src={LogoBlanc} />
          <MenuIconContainer onClick={() => setDrawerOpen(true)}>
            <MenuIcon style={{ color: "white" }} />
          </MenuIconContainer>
          <LinksContainer>
            {links.map((linkProps, index) => (
              <NavBarLink {...linkProps} key={index} />
            ))}
            <DemoButtonContainer
              href={"https://calendly.com/commercial-111/demo-sympl"}
            >
              <DemoButtonTitle>Demander une démo</DemoButtonTitle>
            </DemoButtonContainer>
          </LinksContainer>
        </Container>
      </Root>
    </>
  );
};

export default NavBar;
