import styled from "styled-components";
import SectionTitle from "../../../atoms/SectionTitle";
import { PageContent } from "../../../layout/PageContent";

export const Root = styled.div`
  width: 100%;
  background-color: #faf9f9;
  aspect-ratio: 5/2;
  display: flex;
  flex-direction: column;
  @media (max-width: 1230px) {
    padding-bottom: 30px;
  }
`;

export const Title = styled(SectionTitle)`
  width: 100%;
  text-align: center;
  margin-bottom: 0;
`;

export const CustomPageContent = styled(PageContent)`
  display: block;
`;
