import React from "react";
import Tools from "../../../images/tools.png";
import Inventory from "../../../images/inventory.svg";
import Shipping from "../../../images/shipping.svg";
import Returns from "../../../images/returns.svg";
import Euros from "../../../images/euros.png";
import WhySymplItemProps from "../../../interfaces/props/WhySymplItemProps";
import SAV from "../../../images/photo_sav.png";
import Picking from "../../../images/picking.png";
import Stock from "../../../images/stock.png";
import Packed from "../../../images/packed.png";
import OnDemand from "../../../images/sur-mesure.png";
import Asphalte from "../../../images/asphalte.png";
import Fempo from "../../../images/fempo.png";
import Uber from "../../../images/uber.png";
import PRS from "../../../images/prs.png";
import Veepee from "../../../images/veepee.png";
import Angarde from "../../../images/angarde.png";
import AsphalteSmall from "../../../images/asphalte-small.png";
import DijoSmall from "../../../images/dijo-small.png";
import CarouselItemProps from "../../../interfaces/props/CarouselItemProps";
import Laptop from "../../../images/laptop.png";
import Unified1 from "../../../images/unified1.png";
import Unified2 from "../../../images/unified2.png";
import UsersTalkItemProps from "../../../interfaces/props/UsersTalkItemProps";

export const companiesLogo: string[] = [
  Asphalte,
  Angarde,
  Fempo,
  Uber,
  PRS,
  Veepee,
];

export const offers: OfferItemProps[] = [
  {
    imgSrc: Tools,
    title: "Interface",
    body: "Visualisez l'etat de vos commandes et de votre stock en temps réel",
    color: "#00C2FF",
  },
  {
    imgSrc: Inventory,
    title: "Stockage",
    body: "Vos produits sont stockés dans nos entrepôts",
    color: "#0088FF",
  },
  {
    imgSrc: Shipping,
    title: "Expéditions",
    body: "99% des commandes sont expédiées le jour même",
    color: "#5856D6",
  },
  {
    imgSrc: Returns,
    title: "Gestion des retours",
    body: "Vos produits sont nettoyés et reconditionnés par nos équipes",
    color: "#22BE3A",
  },
  {
    imgSrc: Euros,
    title: "Offre tarifaire",
    body: "Notre algorithme choisit le meilleur tarif parmi plus d'une dizaine de transporteurs",
    color: "#FFD60A",
  },
];

export const whySympl: WhySymplItemProps[] = [
  {
    imageSrc: Stock,
    title: "Aussitôt commandé, aussitôt expédié",
    titleBackgroundColor: "linear-gradient(0.90turn, #00E3FF, #00C2FF)",
  },
  {
    imageSrc: OnDemand,
    title: "Processus d'emballage sur mesure",
    titleBackgroundColor: "linear-gradient(0.90turn, #00C2FF, #0088FF)",
  },
  {
    imageSrc: Picking,
    title: "Reconditionnement des produits retournés",
    titleBackgroundColor: "linear-gradient(0.90turn,#0088FF, #5856D6)",
  },
  {
    imageSrc: Packed,
    title: "97% des emballages recyclés",
    titleBackgroundColor: "linear-gradient(0.90turn, #ADD747, #32D74B)",
  },
  {
    imageSrc: SAV,
    title: "SAV dédié à vos litiges",
    titleBackgroundColor: "linear-gradient(0.90turn, #FFE445, #FFD60A)",
  },
];

export const uniformedPlatform: CarouselItemProps[] = [
  {
    image: Laptop,
    title:
      "Installez simplement le connecteur Sympl sur votre plateforme e-commerce",
  },
  {
    image: Unified1,
    title: "Une webapp complète et intuitive accessible partout",
  },
  {
    image: Unified2,
    title: "Toute votre logistique en un coup d'oeil",
  },
];

export const usersTalks: UsersTalkItemProps[] = [
  {
    logo: AsphalteSmall,
    text:
      "« Sympl, notre logisticien historique, nous permet de mettre le client au cœur de nos actions. Grâce à une" +
      " intégration tech et métier flexibles. On peut proposer une meilleure expérience à nos clients. On peut ainsi " +
      "proposer des services pratiques et innovants à notre communauté. Ce partenariat nous a permis de faire évoluer" +
      " et optimiser notre externalisation logistique afin de contrôler notre business modèle de précommandes. »",
  },
  {
    logo: DijoSmall,
    text:
      "« Sympl est un partenaire de confiance depuis le lancement de notre entreprise. Au-delà de la qualité " +
      "de leur travail et de leur professionnalisme, Sympl a toujours fait preuve de beaucoup de réactivité et de " +
      "proactivité pour nous apporter les meilleures solutions. C'est un réel plaisir de collaborer avec leur équipe" +
      " au quotidien ! »",
  },
];
