import styled from "styled-components";
import Text from "./Text";

const SectionTitle = styled(Text)`
  font-size: 45px;
  margin-bottom: 45px;
  margin-top: 60px;
  @media (max-width: 900px) {
    margin-top: 30px;
    font-size: 30px;
  }
`;

export default SectionTitle;
